* {
  margin: 0px;
  padding: 0px;
  font-family: Inter, sans-serif !important;
}

$blueBg:  #e1485c;
$textBg:   #2d2d2d;

body {
  background-color: #fff;
}

.setting-box {
  border-radius: 12px;
  border: 1px solid #dce0e2;
  background: #fff;
  padding: 15px 10px 15px 11px;
}


.height {
  height: 130px;
}

.video-container {
  position: relative;
  width: 80px;
  height: 80px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.select-time-selector select {
  border-radius: 6px;
  border: 1px solid #dce0e2;
  background: #fff;
  padding: 6px 8px;
  height: 43px;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  &:focus {
    outline: none;
  }
}

.select-time-selector h6 {
  color: #7b7b7b;
  font-size: 14px;
  display: block;
  margin-top: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 1px;
}

.text-label {
  color: #7b7b7b;
  font-size: 14px;
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 1px;

}

.logout-listBox {
  .active {
    .MuiButtonBase-root {
      border-right: 0px !important;
      background: white;
      path {
        fill: #C6D2D8 !important;
      }
      .MuiTypography-root {
        color: #585858 !important;
        opacity: 0.8 !important;
      }
    }
  }
  .MuiButtonBase-root {
    background: white !important;
    &:hover {
      background: #f6fafb !important;
      border-right: 2px solid $blueBg !important;
      path {
        fill: $textBg !important;
      }
      .MuiListItemText-root {
        span {
          color: #2d2d2d !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-form {
      border-radius: 32px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .login-body {
        display: flex;
        align-items: center;
        border-radius: 32px;
        // border: 1px solid rgba(0, 0, 0, 0.08);
        // background: #e1485c;
        box-shadow: 9px 13px 19px 5px rgba(36, 36, 36, 0.08);
        .login-right {
          .login-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 27px;
            img {
              border-radius: 14px;
              width: 57px;
              height: 57px;
            }
            span {
              color: #fff;
              font-size: 23px;
              margin-left: 10px;
              font-weight: 800;
            }
          }
          .login-img {
            img {
              width: 350px;
              height: auto;
              margin-top: 34px;
            }
          }
        }
        .login-top {
          padding: 10px 0px 6px 0px;
          box-shadow: 1px 1px 14px 0px rgba(36, 36, 36, 0.08);
          border-radius: 32px 32px 0px 0px;
          width: 100%;
          background : #e1485c;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
            border-radius: 12px;
          }
          span {
            color: #fff;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 16px;
          }
        }
        .login-content {
          width: 100%;
          padding: 37px 20px;
          background-color: white;
          border-radius: 0px 29px 31px 0px;
          .login-mobile-img {
            display: none;
          }
          h4 {
            font-size: 33px;
            font-style: normal;
            font-weight: 900;
            color: #e1485c;;
            line-height: normal;
            span {
              display: block;
            }
          }
          h6 {
            color: #7a7a7a;
            font-size: 14px;
            font-weight: 500;
            margin: 20px 0px 30px 0px;
            line-height: normal;
          }
          .custom-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            button {
              margin-top: 28px;
              border-radius: 100px;
              background : #e1485c;
              border: none;
              height: 48px;
              font-size: 17px !important;
              max-width: 200px;
              width: 100%;
              margin-left: 5px;
            }
          }
          .custom-input {
            label {
              margin-bottom: 8px;
              margin-left: 4px;
              color: #707070;
              font-size: 14px;
              opacity: 0.9;
              font-weight: 600;
            }
            input {
              border-radius: 60px;
            }
          }
        }
      }
    }
  }
}

.navbar-open {
  header {
    margin-left: 240px !important;
  }
  .MuiToolbar-root {
    justify-content: space-between;
  }
}
.navbar {
  padding: 0px !important;
  header {
    background: #e1485c;
    margin-left: 65px;
    width: auto !important;
    left: 0;
  }
  .MuiDrawer-paper {
    overflow: hidden !important;
    .MuiList-root {
      overflow: scroll;
      padding-top: 0px !important;
    }
  }
  .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #cdcdcd;
    img {
      width: 50px;
      height: auto;
      border: 1px solid #cdcdcd;
      border-radius: 14px;
      cursor: pointer;
    }
    span {
      display: none;
    }
  }
  .MuiToolbar-root {
    .admin-profile {
      display: flex;
      align-items: flex-end;
      width: 100%;
      cursor: pointer;
      justify-content: flex-end;
      .admin-profile-details {
        padding: 5px 6px 5px 16px;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.18);
        display: flex;
        align-items: center;
        img {
          border-radius: 50%;
          object-fit: cover;
          width: 30px;
          height: 30px;
        }
        span {
          margin-right: 6px;
          white-space: nowrap;
          text-transform: capitalize;
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .MuiTypography-root {
      color: #fff;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .MuiList-root {
    .active {
      .MuiButtonBase-root {
        background: #f6fafb;
        border-right: 2px solid $blueBg;
        path {
          fill: $blueBg;
        }
        .MuiListItemText-root {
          span {
            color: $blueBg
            ;
            opacity: 1;
          }
        }
      }
    }
    .MuiButtonBase-root {
      padding: 15px 28px;
      &:hover {
        background: #f6fafb;
        border-right: 2px solid $blueBg;
        path {
          fill: $textBg;
        }
        .MuiListItemText-root {
          span {
            color: $textBg;
            opacity: 1;

          }
        }
      }
      .MuiListItemIcon-root {
        svg {
          width: 26px !important;
          height: 26px !important;
        }
      }
      .MuiListItemText-root  {
        span {
          color: #585858;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          opacity: 0.8;
          line-height: normal;
        }
      }
    }
  }
}

.navbar-open {
  .MuiList-root {
    .MuiButtonBase-root {
      padding: 8px 16px !important;
      margin: 5px 0px;
    }
  }
  .sidebar-logo {
    span {
      display: block;
      margin-left: 11px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      opacity: 0.8;
      line-height: normal;
    }
  }
  .title-nav {
    display: none;
  }
}
.mainPage {
  background: #f4f8fc;
  min-height: calc(100vh - 63.99px);
  margin-left: 65px;
  padding: 15px;
  height: 100%;
}
.mainPage-open {
  margin-left: 240px !important;
}

.dashboardPage {
  h3 {
    color: $textBg;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dashboard-card {
    border-radius: 14px;
    background: #fff;
    box-shadow: 5px 5px 6px 0px rgba(0, 0, 0, 0.06);
    padding: 18px;
    width: 100%;
    display: flex;
    width: 100%;
    max-width: 400px;
    align-items: center;
    .dashboard-card-left {
      width: 100%;
      h6 {
        color: #686868;
        text-decoration: underline;
        cursor: pointer;
        font-family: Inter;
        font-size: 20px;
        margin-bottom: 10px !important;
        margin: 0px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      h4 {
        color: #ff1d1d;
        font-family: Inter;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 17px;
      }
      .custom-btn {
        margin-top: 20px;
        button {
          border-radius: 8px;
          background : #e1485c;
          outline: none;
          border: none;
          display: flex;
          flex-direction: row-reverse;
          padding: 7px 11px !important;
          height: 40px;
          align-items: center;
          span {
            font-size: 14px;
          }
        }
        svg {
          margin-left: 5px;
        }
        span {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    .dashboard-card-right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        border-radius: 14px;
        border: 1px solid #e8e8e8;
        background: #fff;
        padding: 3px;
      }
    }
  }
}

.allApp-page {
  .allApp-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h6 {
      color: $textBg;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .new-button {
      .custom-btn {
        display: flex;
        justify-content: end;
        align-items: center;
      }
      button {
        border-radius: 8px;
        color: white;
        outline: none;
        border: none;
        background : #e1485c;
        padding: 13px;
        display: flex;
        align-items: center;
        height: 40px;
        svg {
          margin-right: 3px;
        }
        span {
          color: #fff;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
    table {
      tbody {
        .logo-image {
          img {
            width: 48px;
            height: 48px;
            border-radius: 7px;
            object-fit: cover;
          }
        }
        .playstoreimg {
          img {
            cursor: pointer;
            width: 45px;
            height: 45px;
          }
        }
        .custom-toggleSwitch {
          .toggle {
            input[type="checkbox"]::after {
              width: 20px;
              height: 20px;
            }
            input[type="checkbox"] {
              width: 57px;
              height: 27px;
            }
            input:checked[type="checkbox"]::after {
              left: 31px;
            }
          }
        }
        .edit-btn {
          width: 39px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background : #e1485c;
          svg {
            font-size: 21px;
          }
        }
        .clone-btn {
          width: 39px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: linear-gradient(95deg, #0e203c 0%, #0f2246 100%);
          svg {
            font-size: 21px;
          }
        }
        .move-btn {
          width: 39px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: linear-gradient(95deg, #0e203c 0%, #0f2246 100%);
          svg {
            font-size: 21px;
          }
        }
        .delete-btn {
          width: 39px;
          height: 39px;
          border-radius: 10px;
          background: radial-gradient(
            486px at 37.5% -17.2%,
            rgb(255, 2, 2) 0%,
            rgb(254, 78, 172) 90%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 21px;
          }
        }
        .edit-text-input {
          width: 100%;
          button {
            background-color: transparent;
            border: none;
          }
          div[editext="view-container"] {
            justify-content: space-between;
          }
          div[editext="view"] {
            color: #585858;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            opacity: 0.8;
            white-space: nowrap;
            max-width: 75px;
            overflow: hidden;
            line-height: normal;
          }
          input[editext="input"] {
            width: 100px;
            height: 37px;
            border-radius: 7px;
            color: #585858;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            overflow: hidden;
            opacity: 0.8;
            line-height: normal;
          }
          button[editext="edit-button"] {
            color: $blueBg;
            font-size: 24px;
            padding: 0px;
            ::before {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

.new-appCreate {
  .mainDiaogBox {
    width: 100%;
    min-width: 550px !important;
    max-width: 550px !important;
    .dialog-head {
      display: flex;
      justify-content: space-between;
      h4 {
        color: $blueBg;
        visibility: visible !important;
        color: $textBg;
        font-size: 19px;
        margin: 0px;
        font-weight: 700;
      }
      .MuiButtonBase-root {
        svg {
          font-size: 26px;
          fill: #aaaaaa;
        }
      }
    }
    .dialog-body {
      margin-top: 10px;
      .custom-input {
        margin: 10px 0px;
      }
      .imgShow {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
          height: 70px;
          object-fit: cover;
          border-radius: 11px;
        }
      }

      // .devloper-select {
      //   .MuiAutocomplete-root {
      //     width: 100%;
      //     .MuiInputBase-root {
      //       height: 48px;
      //       outline: none;
      //       border: 1px solid #d9d9d9;
      //       width: 100%;
      //       border-radius: 6px;
      //       border: 1px solid #ececec;
      //     }
      //     fieldset {
      //       border: none !important;
      //       outline: none !important;
      //     }
      //   }
      //   // .Mui-focused {
      //   //   background-color: white !important;
      //   //   input {
      //   //     background-color: white !important;
      //   //   }
      //   // }
      // }
    }
    .dialog-footer {
      .cancel-btn {
        border-radius: 6px;
        height: 40px;
        max-width: 100px;
        min-width: 100px;
        width: 100%;
        background: linear-gradient(180deg, #ea1919 0%, #f91d1d 100%);
        color: #fff;
        font-size: 16px;
        margin-right: 15px;
        font-weight: 500;
      }
      .submit-btn {
        border-radius: 6px;
        height: 40px;
        max-width: 100px;
        width: 100%;
        background: linear-gradient(180deg, #0379ce 0%, #0e4dee 100%);
        color: #fff;
        font-size: 16px;
        min-width: 100px;
        font-weight: 500;
      }
    }
  }
}
.plan-table {
  .custom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .delete-btn-plan {
      width: 39px;
      height: 39px;
      border-radius: 10px;
      background: radial-gradient(
        486px at 37.5% -17.2%,
        rgb(255, 2, 2) 0%,
        rgb(254, 78, 172) 90%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 21px;
      }
    }
  }
}
.error-box {
  height: 100%;
  width: 100%;
  position: fixed;
  // background: url(../../assets/images/background/error-bg.jpg) no-repeat center center $white !important ;
  .error-body {
    padding-top: 5%;
    height: 100%;
    width: 100%;
    position: fixed;
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }
  }
  .error-title {
    font-size: 210px;
    font-weight: 900;
    color: #2c2b2e;
    line-height: 210px;
  }
  .error-subtitle {
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}
.moved-app {
  .movedApp-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h6 {
      color: #e1485c;;
      font-size: 17px;
      font-style: normal;
      margin-bottom: 0px;
      font-weight: 700;
      line-height: normal;
    }
    .search-input {
      display: flex;
      justify-content: flex-end;
    }
    table {
      tbody {
        .image-showTable {
          img {
            width: 48px;
            height: 48px;
            border-radius: 7px;
            object-fit: cover;
          }
        }
        .custom-btn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .edit-btn {
          width: 39px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background : #e1485c;
          svg {
            font-size: 21px;
          }
        }
        .move-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 11px;
          width: 105px;
          border-radius: 6px;
          height: 35px;
          background: linear-gradient(95deg, #0e203c 0%, #0f2246 100%);
          span {
            color: white;
            color: #fff;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            margin-left: 8px;
            line-height: normal;
          }
          svg {
            font-size: 20px;
          }
        }
        .delete-btn {
          width: 39px;
          height: 39px;
          border-radius: 10px;
          background: radial-gradient(
            486px at 37.5% -17.2%,
            rgb(255, 2, 2) 0%,
            rgb(254, 78, 172) 90%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 21px;
          }
        }
      }
    }
  }
}

.new-appEdit {
  position: relative;
  .app-edit-body {
    position: sticky;
    top: 0;
    background-color: #f4f8fc;
    z-index: 99;
    .top-head-edit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .app-nameDetails {
        display: flex;
        align-items: center;
        border: 1px solid #dce0e2;
        border-radius: 11px;
        img {
          width: 45px;
          border-radius: 10px;
          height: 45px;
          object-fit: cover;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        h6 {
          color: #6265ff;
          font-size: 17px;
          font-weight: 600;
          margin-right: 15px;
          text-transform: capitalize;
          margin-left: 12px;
          margin-bottom: 0px;
        }
      }
    }
  }
  .MuiTabs-scroller {
    border-bottom: 1px solid #dce0e3;
    .MuiButtonBase-root {
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize !important;
      color: #a1a8ac;
      min-height: 48px !important;
      padding: 0px 16px;
      svg {
        fill: #a1a8ac !important;
        width: 20px;
        height: 20px;
      }
      .MuiTouchRipple-root {
        border-radius: 9px 9px 0px 0px !important;
      }
    }
    .Mui-selected {
      border-bottom: 2px solid #2d2d2d!important;
      border: 1px solid #dce0e3;
      border-radius: 9px 9px 0px 0px;
      color: #e1485c;
      svg {
        fill: #e1485c !important;
      }
    }
    .MuiTabs-indicator {
      background-color: transparent;
    }
  }
  .edit-renderComponent {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.04);
    margin-top: 20px;
    padding: 29px 23px 29px 23px;
    .add-details {
      .image-upload {
        position: relative;
        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 125px;
          height: 125px;
          cursor: pointer;
          border-radius: 19px;
          border: 2px dashed #dce0e2;
          background: #fff;
          svg {
            color: #dce0e2;
            width: 35px;
          }
        }
        .edit-icon {
          display: none;
        }
        .show-img {
          position: absolute;
          top: 0;
          cursor: pointer;
          img {
            border-radius: 16px;
            width: 125px;
            height: 125px;
            object-fit: cover;
          }
          &:hover {
            opacity: 0.8 !important;
            .edit-icon {
              display: block;
              background: #1f1c30;
              border-radius: 50px;
              color: #fff;
              cursor: pointer;
              font-size: 15px;
              height: 35px;
              width: 35px;
              left: 50%;
              opacity: 1 !important;
              position: absolute;
              text-align: center;
              top: 50%;
              -webkit-transform: translate(-50%, -50%) !important;
              transform: translate(-50%, -50%) !important;
              -ms-transform: translate(-50%, -50%) !important;
              transition: 0.5s ease !important;
              svg {
                margin-top: 5px;
                width: 22px;
              }
            }
          }
        }
      }
      .show-img-content {
        position: relative;
        width: 125px;
        height: 125px;
      }
      .custom-input {
        input {
          border-radius: 6px;
          border: 1px solid #dce0e2;
          background: #fff;
          color: #4b4d58;
          font-size: 14px;
        }
        label {
          color: #7b7b7b;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .policy-note {
        .inputData {
          margin-right: 0px !important;
        }
      }
      .privacy-policy {
        padding-left: 11px;
        padding-right: 0px;
        .inputData {
          margin-right: 13px;
          label {
            color: #7b7b7b;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          textarea {
            border-radius: 6px;
            border: 1px solid #dce0e2;
            background: #fff;
            color: #4b4d58;
            font-size: 14px;
            resize: none;
            &:focus-visible {
              outline: none;
            }
          }
        }
        .custom-input {
          height: calc(100% - 10px);
          input {
            height: 100%;
          }
        }
      }
      .label-input {
        color: #7b7b7b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 11px 0px;
        margin-left: 4px;
      }
      .moreApp-selector {
        .toggle-switch-more-app {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .toggle {
            margin-right: 8px;
          }
        }
        .moreApp-option {
          img {
            width: 25px;
            height: 25px;
            border-radius: 4px;
          }
          span {
            margin-left: 10px;
            font-size: 12px;
          }
        }
      }
      .MuiAutocomplete-roo {
        input {
          color: #4b4d58;
          font-size: 14px;
        }
        fieldset {
          border-radius: 6px;
          border: 1px solid #dce0e2 !important;
          border-color: #dce0e2 !important;
          background: #fff;
        }
      }
      .submit-btn {
        border-radius: 8px;
        padding: 10px 23px;
        background : #e1485c;
        color: #fff;
        font-size: 15px;
      }
    }
    .monetization-edit {
      .custom-input {
        input {
          font-size: 13px;
        }
      }
      .ad-level {
        h6 {
          color: #4b4d58;
          margin: 0px;
          font-size: 19px;
          font-weight: 600;
        }
        select {
          border-radius: 6px;
          border: 1px solid #dce0e2;
          background: #fff;
          margin-left: 10px;
          padding: 6px 8px;
          cursor: pointer;
          text-transform: capitalize;
          &:focus {
            outline: none;
          }
        }
        .ad-level-body {
          margin-top: 25px;
          .ad-level-box {
            border-radius: 8px;
            border: 1px solid #dce0e2;
            padding: 15px 17px 8px 15px;
            background: #fff;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
            h5 {
              color: #4b4d58;
              font-size: 16px;
              font-weight: 600;
            }
            .inputData {
              margin-top: 5px;
              textarea {
                border-radius: 6px;
                border: none;
                background: #fafafa;
                resize: none;
                &:focus {
                  outline: #d7d3d3;
                  border: 1px solid;
                }
              }
              label {
                color: #95a4ac;
                font-size: 14px;
                font-weight: 500;
                margin-left: 0px;
              }
            }
          }
        }
      }
      .add-revenue {
        margin-top: 25px;
        .switchData-btn {
          position: absolute;
          top: 11%;
          right: 50%;
          width: 40px;
          height: 40px;
          transform: translate(21%, 50%);
          button {
            width: 40px;
            height: 40px;
            padding-left: 2px;
            cursor: pointer;
            background-color: transparent;
            svg {
              fill: #6d72ff;
              font-size: 33px;
            }
          }
        }
      }
      .interstitial-ad {
        .label-text {
          color: #7b7b7b;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 7px;
          margin-left: 1px;
        }
        .interstitial-ad-box {
          border-radius: 12px;
          border: 1px solid #dce0e2;
          background: #fff;
          padding: 2px 13px 15px 13px;
          box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
          .custom-input {
            label {
              color: #7b7b7b;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 7px;
              margin-left: 1px;
            }
            input {
              border-radius: 6px;
              border: 1px solid #dce0e2;
              background: #fff;
            }
          }
          .pre-load-ads {
            margin-top: 4px;
          }
        }
        .custom-ad-box {
          border-radius: 12px;
          border: 1px solid #dce0e2;
          background: #fff;
          padding: 13px 13px 15px 13px;
          height: 100%;
          box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
          .customeAd-option {
            img {
              width: 25px;
              height: 25px;
              border-radius: 4px;
            }
            span {
              margin-left: 10px;
              font-size: 12px;
            }
          }
        }
      }
    }
    .other-setting {
      .e1Box {
        border-radius: 12px;
        border: 1px solid #dce0e2;
        background: #fff;
        padding: 13px 16px 16px 15px;
      }
    }
    .app-setting {
      .custom-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 18px;
      }
      .pBox {
        border-radius: 12px;
        border: 1px solid #dce0e2;
        background: #fff;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
        padding: 13px 16px 16px 15px;
      }
      .setting-field {
      }
      .setting-box {
        border-radius: 12px;
        border: 1px solid #dce0e2;
        background: #fff;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
        padding: 13px 16px 16px 15px;
        .setting-field {
          h6 {
            padding-bottom: 9px;
            border-bottom: 1px solid #e3e0e0;
            margin-top: 9px;
            padding-top: 9px;
            color: #4b4d58;
            font-family: Plus Jakarta Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 8px;
          }
          .setting-title {
            padding-bottom: 9px;
            border-bottom: 1px solid #e3e0e0;
            margin-bottom: 8px;
            margin-top: 9px;
            padding-top: 9px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h6 {
              border: none;
              padding: 0;
              margin: 0;
            }
          }
        }
        .custom-input {
          label {
            color: #7b7b7b;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            margin-left: 1px;
          }
          input {
            border-radius: 6px;
            border: 1px solid #dce0e2;
            background: #fff;
            font-size: 13px;
          }
        }
      }
    }
    .plan-edit {
      .custom-input {
        label {
          color: #7b7b7b;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 8px;
          margin-left: 1px;
        }
        input {
          border-radius: 6px;
          border: 1px solid #dce0e2;
          background: #fff;
          font-size: 13px;
        }
      }
      .plan-edit-left {
        border-radius: 12px;
        border: 1px solid #dce0e2;
        margin: 0px 1px;
        background: #fff;
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
        padding: 13px 16px 16px 15px;
        .image-input {
          margin-top: 10px;
          .label-input {
            color: #7b7b7b;
            font-size: 14px;
            display: block;
            font-weight: 500;
            margin-bottom: 8px;
            margin-left: 1px;
          }
          input {
            display: block !important;
          }
          img {
            width: 100px;
            margin-top: 10px;
            height: 100px;
            border-radius: 10px;
          }
        }
       
      }
    
      .plan-edit-right {
        .cInput-box {
          border-radius: 12px;
          border: 1px solid #dce0e2;
          background: #fff;
          box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.06);
          padding: 13px 16px 16px 15px;
          h6 {
            margin-top: 10px;
            color: #4b4d58;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }
    .vipPlan-edit {
      .dialog {
        .dialog-body {
          max-height: 537px !important;
          .custom-input {
            label {
              color: #7b7b7b;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
              margin-left: 1px;
            }
            input {
              border-radius: 6px;
              border: 1px solid #dce0e2;
              background: #fff;
              font-size: 13px;
            }
          }
          .select-time-selector {
            h6 {
              color: #7b7b7b;
              font-size: 14px;
              display: block;
              margin-top: 15px;
              font-weight: 500;
              margin-bottom: 8px;
              margin-left: 1px;
            }
            select {
              border-radius: 6px;
              border: 1px solid #dce0e2;
              background: #fff;
              padding: 6px 8px;
              height: 43px;
              width: 100%;
              font-size: 14px;
              cursor: pointer;
              text-transform: capitalize;
              &:focus {
                outline: none;
              }
            }
          }
          .image-input {
            margin-top: 10px;
            .label-input {
              color: #7b7b7b;
              font-size: 14px;
              display: block;
              font-weight: 500;
              margin-bottom: 8px;
              margin-left: 1px;
            }
            input {
              display: block !important;
            }
            img {
              width: 100px;
              margin-top: 10px;
              height: 100px;
              border-radius: 10px;
            }
          }
          .imgShow {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 80px;
              height: 70px;
              object-fit: cover;
              border-radius: 11px;
            }
          }
          .inputData {
            margin-top: 5px;
            label {
              color: #7b7b7b;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
              margin-left: 1px;
            }
            textarea {
              margin-top: 0px;
            }
            p {
              font-size: 13px;
              color: red;
              margin-bottom: 0 !important;
              margin-left: 1px;
            }
          }
        }
      }
    }
    .notificationPush {
      .img-notifi {
        width: 80px;
        height: 60px;
        border-radius: 6px;
        object-fit: cover;
        margin-top: 10px;
      }
      .image-inputBox {
        label {
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #7b7b7b;
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 1px;
        }

        .image-input {
          position: relative;
          input {
            border-radius: 6px;
            border: 1px solid #c5c5c5;
            background: #fff;
            font-size: 13px;
            padding: 15px;
            outline: none;
            height: 38px;
            width: 100%;
            font-size: 14px;
            padding-left: 57px;
            &:hover {
              background-color: white;
              border-color: #bab5b5 !important;
            }
          }
          .img-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            display: flex;
            height: 37px;
            align-items: center;
            img {
              width: 48px;
              height: 36px;
              padding: 3px 5px;
              object-fit: cover;
              border-radius: 6px;
            }
          }
        }
      }
    }
    .json-formeter {
      .button-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .move-btn {
          background: linear-gradient(
            180deg,
            #6d72ff 0%,
            #6a5dff 99.99%
          ) !important;
          margin-right: 10px;
          border: 1px solid transparent;
          padding: 0.375rem 0.75rem;
          border-radius: 6px;
          font-size: 0.875rem;
          margin-bottom: 5px;
          font-weight: 400;
          line-height: 1.5;
          span {
            color: white;
            color: #fff;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            margin-left: 8px;
            line-height: normal;
          }
          svg {
            font-size: 20px;
          }
        }
        .remove-space {
          background: radial-gradient(
            486px at 37.5% -17.2%,
            rgb(255, 2, 2) 0%,
            rgb(254, 78, 172) 90%
          );
          border: 1px solid transparent;
          padding: 0.375rem 0.75rem;
          border-radius: 6px;
          font-size: 0.875rem;
          margin-bottom: 5px;
          font-weight: 400;
          line-height: 1.5;
          span {
            color: white;
            color: #fff;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            margin-left: 8px;
            line-height: normal;
          }
          svg {
            font-size: 20px;
          }
        }
      }
      .error {
        background-color: rgba(255, 0, 0, 0.5294117647);
        color: white;
        padding: 2px 4px;
        font-size: 14px;
        border-radius: 5px;
      }
      .code-editor {
        display: flex;
        flex-direction: row;
        margin: 20px 0px;
        .input-area {
          width: 100%;
          min-width: 50%;
        }

        .output-area {
          width: 100%;
        }
      }
    }
  }
}

.customAdTable {
  background-color: #fff;
  margin: 10px;
  padding: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 13px;
  h6 {
    margin-top: 10px;
    color: #e1485c;;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.shortVideo {
  .custom-btn {
    span {
      font-size: 16px;
    }
  }
  .simple-table {
    video {
      border-radius: 9px;
    }
    tbody {
      td {
        text-align: center;
        .custom-btn {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.ShortVideoDialogue {
  .video-showBox {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    gap: 15px;
    margin-top: 5px;
    video {
      border-radius: 14px;
      width: 100px;
      height: 100px;
    }
  }
  h4 {
    padding-top: 8px;
    visibility: visible !important;
    color: #e1485c;;
    font-size: 19px;
    margin: 0px;
    font-weight: 700;
  }
  .MuiButtonBase-root {
    cursor: pointer;
    svg {
      font-size: 26px;
      fill: #aaaaaa;
    }
  }
  .inputData {
    label {
      color: #585858;
      font-size: 15px;
      font-weight: 600;
      opacity: 0.9;
      line-height: normal;
      margin-left: 4px;
    }
    textarea {
      &:focus {
        border: 1px solid #c5c1c1;
        outline: none;
      }
    }
    p {
      // font-size: 14px;
      // width: 95%;
      // margin-top: 10px;
      // margin-bottom: 8px;
    }
    .errorMessage-text {
      margin-top: 1px;
      margin-bottom: 10px !important;
      font-size: 13px;
      color: red;
      margin-left: 1px;
      margin-bottom: 8px !important;
      text-transform: capitalize;
    }
  }
  p {
    font-size: 14px;
    width: 95%;
    margin-bottom: 8px;
  }
}

.createCustomAd {
  .dialog-body {
    padding: 0px 10px;
    .inputData {
      label {
        color: #585858;
        font-size: 15px;
        font-weight: 600;
        opacity: 0.9;
        line-height: normal;
        margin-left: 4px;
      }
      p {
        font-size: 13px;
      }
    }
    .form-input[type="file"] {
      margin: 4px 0px 7px 0px;
    }
    .image-start {
      display: grid;
      grid-template-columns: repeat(4, 70px);
      gap: 9px;
      max-height: 151px;
      overflow-y: scroll;
      padding: 11px 10px;
      padding-bottom: 0px;
      .image-preview-multipal {
        .image-box {
          position: relative;
          img {
            border-radius: 9px;
            object-fit: cover;
            width: 65px !important;
            height: 65px !important;
          }
          .MuiButtonBase-root {
            position: absolute;
            right: -7px;
            padding: 0;
            z-index: 111;
            background : #e1485c;
            color: white;
            width: 18px;
            height: 18px;
            top: -6px;
            svg {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.gamePage {
  .game-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h6 {
      color: $textBg;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .custom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .delete-btn {
        width: 39px;
        height: 39px;
        border-radius: 10px;
        background: radial-gradient(
          486px at 37.5% -17.2%,
          rgb(255, 2, 2) 0%,
          rgb(254, 78, 172) 90%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 21px;
        }
      }
      .edit-btn {
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background : #e1485c;
        svg {
          font-size: 21px;
        }
      }
    }
    tbody {
      td {
        img {
          width: 48px;
          height: 48px;
          border-radius: 7px;
          object-fit: cover;
        }
        .MuiRating-root {
          color: #faaf00 !important;
          font-size: 18px !important;
          opacity: 1 !important;
          span {
            color: #faaf00 !important;
            opacity: 1 !important;
            font-size: 18px !important;
          }
        }
      }
    }
  }
  .gameDialogue {
    .mainDiaogBox {
      max-width: 600px;
      .custom-input {
        margin: 10px 0px;
        input {
          border-radius: 6px;
          border: 1px solid #ececec;
        }
      }
      .image-input {
        margin: 10px 0px;
        input {
          width: 100%;
          display: block !important;
        }
        img {
          width: 100px;
          margin-top: 10px;
          height: 100px;
          border-radius: 10px;
        }
      }
      .rating-show {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .MuiRating-root {
          margin: 10px 0px;
        }
      }
    }
  }
}

.giftPage {
  h6 {
    color: $blueBg;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    line-height: normal;
  }

  .custom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .delete-btn {
      width: 39px;
      height: 39px;
      margin-left: 0px;
      border-radius: 40px;
      background: radial-gradient(
        486px at 37.5% -17.2%,
        rgb(255, 2, 2) 0%,
        rgb(254, 78, 172) 90%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 21px;
      }
    }
    .edit-btn {
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      margin-right: 0px;
      justify-content: center;
      border-radius: 40px;
      background : #e1485c;
      svg {
        font-size: 21px;
      }
    }
  }
  .title-custom {
    padding-bottom: 0px !important;
    h5 {
      margin-bottom: 0px;
    }
    span {
      display: none;
    }
  }
  .gift-tab {
    display: flex;
    justify-content: flex-end;
    .MuiBox-root {
      .MuiTabs-root {
        .MuiButtonBase-root {
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize !important;
          color: #a1a8ac;
          min-height: 48px !important;
          padding: 0px 16px;
          svg {
            fill: #a1a8ac !important;
            width: 20px;
            height: 20px;
          }
          .MuiTouchRipple-root {
            border-radius: 9px 9px 0px 0px !important;
          }
        }
        .Mui-selected {
          border-bottom: none !important;
          border: 1px solid #dce0e3;
          border-radius: 9px 9px 0px 0px;
          color: #e1485c;
          svg {
            fill: #e1485c !important;
          }
        }
      }
    }
  }

  .css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #2d2d2d;
  }

  .not-foundText{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    opacity: 0.8;
    line-height: normal;
  }

  .game-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    #demo-customized-button {
      border-radius: 8px;
      background : #e1485c;
      padding: 13px;
      text-transform: capitalize;
      font-weight: normal;
      display: flex;
      align-items: center;
      height: 40px;
    }
    .gift-show {
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
      border-radius: 13px;
      max-height: 80vh;
      overflow: scroll;
      height: 100%;
      h6 {
        color: $textBg;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        text-transform: capitalize;
        line-height: normal;
      }

      .giftCategoryWise {
        margin: 10px 0px;
        h5 {
          color: #e1485c;;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          margin: 0px;
          text-transform: capitalize;
          line-height: normal;
        }
        .giftBox {
          margin-top: 5px;
          .gift-Image {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #f0f0f0;
            padding: 19px;
            margin-top: 10px;
            border-radius: 8px;
            background-color: rgb(214 215 216 / 34%);
            .giftImageShow {
              img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                border: 2px solid #6b65ff;
              }
            }
            .giftText {
              display: flex;
              width: 100%;
              flex-direction: column;
              align-items: center;
              margin-top: 10px;
              h5 {
                color: #e1485c;;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                margin: 0px;
                text-transform: capitalize;
                line-height: normal;
              }
              h3 {
                color: #585858;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                margin: 10px 0px;
                text-transform: capitalize;
                line-height: normal;
              }
              .gift-button {
                display: flex;
                .custom-btn {
                  padding-top: 10px;
                  margin: 0px 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  .giftDialog {
    .mainDiaogBox {
      max-width: 600px;
      .dialog-body {
        overflow: hidden;
      }
      .custom-input {
        margin: 10px 0px;
        input {
          border-radius: 6px;
          border: 1px solid #ececec;
        }
      }
      .image-input {
        margin: 10px 0px;
        input {
          width: 100%;
          display: block !important;
        }
        img {
          width: 100px;
          margin-top: 10px;
          height: 100px;
          border-radius: 10px;
        }
      }
      .categorySelect {
        label {
          font-style: normal;
          font-weight: 500;
          margin-bottom: 10px !important;
          line-height: normal;
          color: #7b7b7b;
          font-size: 14px;
          margin-left: 1px;
        }
        .moreApp-option {
          img {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .giftCategory {
    .giftCategoryDialog {
      .mainDiaogBox {
        max-width: 500px;
        .custom-input {
          margin: 10px 0px;
          input {
            border-radius: 6px;
            border: 1px solid #ececec;
          }
        }
        .image-input {
          margin: 10px 0px;
          input {
            width: 100%;
            display: block !important;
          }
          img {
            width: 100px;
            margin-top: 10px;
            height: 100px;
            border-radius: 10px;
          }
        }
      }
    }
    .gift-show {
      h4 {
        color: #585858;
        font-size: 16px;
        font-style: normal;
        margin: 10px 0px;
        text-transform: capitalize;
        line-height: normal;
      }
    }
  }
}

.profilePage {
  .admin-box {
    background-color: #fff;
    // margin: 10px;
    height: 100%;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h3 {
      color: #e1485c;;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 14px;
      border-bottom: 1px solid #e3e0e0;
    }
    .custom-input {
      label {
        color: #7b7b7b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 8px !important;
        line-height: normal;
      }
      input {
        border-radius: 6px;
        border: 1px solid #dce0e2;
        background: #fff;
        padding: 10px;
        height: 39px;
        color: #4b4d58;
        font-size: 14px;
      }
      .passHideShow {
        bottom: 7px;
      }
    }
    .profile-image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      .image-upload {
        position: relative;
        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 125px;
          height: 125px;
          cursor: pointer;
          border-radius: 19px;
          border: 2px dashed #dce0e2;
          background: #fff;
          svg {
            color: #dce0e2;
            width: 35px;
          }
        }
        .edit-icon {
          display: none;
        }
        .show-img {
          position: absolute;
          top: 0;
          cursor: pointer;
          img {
            border-radius: 50%;
            width: 125px;
            height: 125px;
            object-fit: cover;
          }
          &:hover {
            opacity: 0.8 !important;
            .edit-icon {
              display: block;
              background: #1f1c30;
              border-radius: 50px;
              color: #fff;
              cursor: pointer;
              font-size: 15px;
              height: 35px;
              width: 35px;
              left: 50%;
              opacity: 1 !important;
              position: absolute;
              text-align: center;
              top: 50%;
              -webkit-transform: translate(-50%, -50%) !important;
              transform: translate(-50%, -50%) !important;
              -ms-transform: translate(-50%, -50%) !important;
              transition: 0.5s ease !important;
              svg {
                margin-top: 5px;
                width: 22px;
              }
            }
          }
        }
      }
      .show-img-content {
        position: relative;
        width: 125px;
        height: 125px;
      }
      h5 {
        margin-top: 10px;
        margin-bottom: 5px;
        text-transform: capitalize;
        color: #54667a;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
      }
      h6 {
        color: #7b7b7b;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 23px;
        line-height: normal;
        margin-top: 1px;
      }
    }

    .btn-submit {
      background: #e1485c;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      border-radius: 6px;
      font-size: 0.875rem;
      margin-top: 20px;
      margin-bottom: 5px;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.postPage {
  .post-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h6 {
      color: $blueBg;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .custom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .delete-btn {
        width: 39px;
        height: 39px;
        border-radius: 10px;
        background: radial-gradient(
          486px at 37.5% -17.2%,
          rgb(255, 2, 2) 0%,
          rgb(254, 78, 172) 90%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 21px;
        }
      }
      .edit-btn {
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background : #e1485c;
        svg {
          font-size: 21px;
        }
      }
    }
    tbody {
      td {
        img {
          width: 48px;
          height: 48px;
          border-radius: 7px;
          object-fit: cover;
        }
      }
    }
  }
  .mainDiaogBox {
    width: 100%;
    .dialog-body {
      margin-top: 10px;
      padding: 0px 5px;
      h5 {
        color: #f62d51 !important;
        font-weight: 800;
        text-align: center;
        margin-bottom: 15px;
        font-size: 14px;
      }
      .post-box {
        h6 {
          color: #000;
          font-size: 19px;
          font-weight: 700;
          opacity: 0.6;
          text-align: center;
        }
        .inputData {
          margin-top: 10px;
          label {
            color: #7b7b7b;
            font-size: 14px;
            font-weight: 500;
            margin-left: 1px;
          }
          textArea {
            margin-top: 5px;
            border-radius: 6px;
            background: #fff;
            &::placeholder {
              color: rgba(0, 0, 0, 0.87);
            }
          }
          .errorMessage {
            color: red !important;
            opacity: 1 !important;
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

.testId {
  .fbBox {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
  }
  .googleBox {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
  }
  .testId-page {
    h3 {
      color: #e1485c;;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 14px;
      border-bottom: 1px solid #e3e0e0;
    }
    .custom-input {
      label {
        color: #7b7b7b;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        margin-left: 1px;
      }
      input {
        border-radius: 6px;
        border: 1px solid #dce0e2;
        background: #fff;
        font-size: 13px;
      }
    }
  }
}

.DevDialogue {
}

.hostTable {
  .custom-table {
    table {
      tbody {
        tr {
          td {
            .image-showTable {
              grid-gap: 10px;
              display: grid;
              gap: 10px;
              grid-template-columns: auto auto auto auto;
              max-width: -webkit-max-content;
              max-width: max-content;
            }
            .host-bio-text {
              text-align: start !important;
              color: #585858;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              opacity: 0.8;
              line-height: normal;
            }
          }
        }
      }
    }
  }
  .fake-messageBtn {
    svg {
      margin-right: 6px;
    }
    span {
      font-size: 14px;
    }
  }
  .fake-messageDialog {
    .mainDiaogBox {
      min-width: 550px;
    }
    .inputData {
      label {
        color: #7b7b7b;
        font-size: 14px;
        font-weight: 500;
        margin-left: 1px;
      }
      textarea {
        line-height: 27px;
      }
    }
    h5 {
      color: red;
      font-size: 13px;
      text-align: center;
      font-weight: 600;
      margin-top: 10px;
    }
  }
  .host-dialog {
    .dialog-body {
      .inputData {
        label {
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #7b7b7b;
          font-size: 14px;
          margin-bottom: 10px;
          margin-left: 1px;
        }
        textarea {
          margin-top: 0px !important;
        }
      }
      .labelInput {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #7b7b7b;
        font-size: 14px;
        margin-bottom: 3px;
        margin-left: 1px;
      }
      .MuiFormGroup-root {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        .MuiFormControlLabel-root .MuiTypography-root {
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #7b7b7b;
          font-size: 14px;
        }
      }
      .link-img {
        .inputData {
          label {
            display: none;
          }
          textarea {
            margin-top: 5px !important;
          }
          p {
            font-size: 14px !important;
          }
        }
        .img-show {
          grid-gap: 10px;
          display: grid;
          gap: 10px;
          grid-template-columns: repeat(6, 70px);
          overflow: scroll;
          img {
            border-radius: 3px;
            height: 60px;
            object-fit: cover;
            width: 70px;
          }
          .imageRemoveIcon {
            .custom-btn {
              margin-bottom: -7px;
              z-index: 999999999;
              position: relative;
              margin-right: -9px;
              float: right;
              background-color: transparent;
              button {
                background-color: transparent;
                svg {
                  color: red;
                }
              }
            }
          }
        }

        .img-show {
        }
      }
      .video-show {
        video {
          border-radius: 3px;
          height: 100px;
          width: 150px;
        }
      }
    }
  }
}

.mainCustomAd {
  .image-showTable {
    img {
      width: 80px;
      height: 80px;
      border-radius: 7px;
      object-fit: cover;
    }
  }
}

.notification-page {
  .notification-table {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;
    h6 {
      color: $textBg;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .custom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .delete-btn {
        width: 39px;
        height: 39px;
        border-radius: 10px;
        background: radial-gradient(
          486px at 37.5% -17.2%,
          rgb(255, 2, 2) 0%,
          rgb(254, 78, 172) 90%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 21px;
        }
      }
      .edit-btn {
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background : #e1485c;
        svg {
          font-size: 21px;
        }
      }
    }
  }
  .dialog {
    .mainDiaogBox {
      max-width: 550px !important;
    }
  }
}

.moreAppDialogue {
  .mainDiaogBox {
    min-width: 430px !important;
    max-width: 560px !important;
  }
  .image-input {
    margin: 10px 0px;
    input {
      width: 100%;
      display: block !important;
    }
    img {
      width: 100px;
      margin-top: 10px;
      height: 100px;
      border-radius: 10px;
    }
  }
}

.appUser {
  .all-dateBtn {
    height: 40px;
    border-radius: 4px;
    padding: 0px 17px;
    margin-right: 10px;
    font-size: 15px;
    color: rgb(63, 81, 181);
    border: 1px solid rgba(63, 81, 181, 0.5);
    background-color: white;
  }
  .edit-renderComponent {
    padding: 20px 23px 3px 23px !important;
  }
  .total-dataText {
    display: flex;
    justify-content: flex-end;
  }
  .totalUser-box {
    border-radius: 7px;
    padding: 8px 9px;
    display: flex;
    justify-content: center;
    margin: 0px 3px;
    align-items: center;
    border: 1px solid rgba(63, 81, 181, 0.5);
    h6 {
      color: $textBg;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      margin: 0px;
      line-height: normal;
    }
  }
  .custom-table {
    max-height: 373px;
    min-height: 373px;
  }
  .daterange {
    &:focus-visible {
      border-color: rgb(188 188 188) !important;
      outline: none !important;
    }
  }
}

.notification-page {
  .MuiTabs-root {
    .MuiTabs-scroller {
      button {
        text-transform: capitalize !important;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .send-btn-dialogue {
    display: flex;
    flex-direction: row-reverse;
    svg {
      margin-left: 6px;
      font-size: 19px !important  ;
    }
  }
  .send-btn {
    svg {
      margin-right: 6px;
      font-size: 19px !important  ;
    }
  }
  .notification-body {
    .notification-pageBox {
      background-color: #fff;
      margin: 10px;
      padding: 15px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      form {
        h6 {
          color: #e1485c;;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-bottom: 14px;
          border-bottom: 1px solid #e3e0e0;
        }
        .select-app {
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #c5c3c3 !important;
            border-width: 1px !important;
          }
        }
        .devloper-select {
          margin-top: 10px;
        }
        img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 7px;
          margin-top: 11px;
        }
        .title-custom {
          margin-bottom: 0px !important;
        }
        .send-btn {
          button {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 35px !important;
            background: linear-gradient(
              180deg,
              #6d72ff 0%,
              #6a5dff 99.99%
            ) !important;
            span {
              font-size: 14px !important;
            }
            svg {
              margin-left: 7px;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  .specifiedNotiData-dialog {
    .inputData {
      label {
        font-style: normal;
        font-weight: 600 !important;
        line-height: normal;
        color: #7b7b7b;
        font-size: 14px;
        margin-left: 1px;
        opacity: 1 !important;
      }
      p {
        font-size: 14px !important;
      }
    }
    .errorMessage {
      margin-bottom: 10px !important;
      margin-top: -12px !important;
    }
    .labelInput {
      font-style: normal;
      font-weight: 600 !important;
      line-height: normal;
      color: #7b7b7b;
      font-size: 14px;
      margin-left: 1px;
      opacity: 1 !important;
    }
    .MuiFormControl-root {
      .MuiFormGroup-root {
        flex-direction: row !important;
        margin-left: 10px;
      }
    }
    .link-img {
      textarea {
        margin-top: -19px;
      }
    }
    .form-input[type="file"] {
      background: #fff;
      border: 1px solid #ececec;
      border-radius: 6px;
      color: #5e6f82;
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      overflow: hidden !important;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .img-show {
    display: grid;
    grid-template-columns: repeat(6, 70px);
    gap: 10px;
    overflow: scroll;
    img {
      width: 70px;
      height: 60px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
  .specified-dialogue {
    .mainDiaogBox {
      max-width: 475px !important;
      .dialog-body {
        overflow: unset !important;
      }
    }
    .select-moreApp {
      .input-label {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #7b7b7b;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 1px;
      }
      img {
        width: 25px;
        height: 25px;
        border-radius: 4px;
      }
      span {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
  .notification-table {
    .img-showTable {
      display: grid;
      gap: 10px;
      max-width: max-content;
      grid-template-columns: auto auto auto auto;
      .MuiChip-root {
        justify-content: flex-start !important;
        max-width: fit-content !important;
      }
      .imgNotification {
        border-radius: 6px;
        width: 40px;
        object-fit: cover;
        height: 36px;
        margin-right: 8px;
      }
    }
    .img-showTableData {
      display: grid;
      gap: 10px;
      max-width: max-content;
      grid-template-columns: auto auto auto auto;
      .MuiChip-root {
        justify-content: flex-start !important;
        max-width: fit-content !important;
      }
      .imgNotification {
        border-radius: 6px;
        width: 60px;
        object-fit: cover;
        height: 45px;
        margin-right: 8px;
      }
    }
    ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      li {
        color: #585858;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        opacity: 0.8;
        text-align: start;
        line-height: normal;
      }
    }
  }
  .immediatelyNoti {
    background-color: #fff;
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    border-radius: 13px;

    .select-moreApp {
      .input-label {
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #7b7b7b;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 1px;
      }
      img {
        width: 25px;
        height: 25px;
        border-radius: 4px;
      }
      span {
        margin-left: 10px;
        font-size: 12px;
      }
    }
    .show-img-imm {
      margin-top: 10px;
      img {
        width: 80px;
        object-fit: cover;
        height: 60px;
        border-radius: 6px;
      }
    }
    .custom-btn {
      button {
        margin-right: 10px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          180deg,
          #6d72ff 0%,
          #6a5dff 99.99%
        ) !important;
        svg {
          margin-left: 6px !important;
          margin-right: 0px !important;
        }
      }
    }
  }
  .send-notiIcon {
    .check-icon {
      svg {
        color: #09b109;
        font-size: 25px;
        border: 1px dashed;
        border-radius: 50%;
      }
    }
    .pending-icon {
      svg {
        color: #f19f04;
        font-size: 25px;
        border: 1px dashed;
        border-radius: 50%;
      }
    }
  }
}

.select__menu {
  overflow-y: auto;
}

// Dashbard Style :-
.dashboard-userdata{
    border: 1px solid #dce0e2;
}