@media only screen and (max-device-width: 768px) {
  .pagesContent {
    header {
      margin: 0px !important;
      width: 100% !important;
    }
    .navbar-open {
      header {
        margin: 0px !important;
        width: 100% !important;
      }
      .MuiDrawer-root {
        display: block;
      }
      .title-nav {
        display: block !important;
      }
    }
    .mainPage-open {
      position: relative;
      .mainPage-bg {
        position: fixed;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4196078431);
        z-index: 1111;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .MuiDrawer-root {
      display: none;
    }
    .mainPage {
      margin-left: 0px !important;
    }
    .navbar {
      .MuiToolbar-root {
        .MuiTypography-root {
          font-size: 22px;
        }
      }
    }
  }
  .app-edit-body {
    .MuiTabs-scroller {
      overflow: scroll !important;
    }
  }
  .custom-searching {
    input {
      width: calc(100% - 49px);
      max-width: 200px;
    }
  }
  .dialog {
    padding: 0px 15px !important;
  }
  .mainDiaogBox {
    min-width: unset !important;
    max-width: unset !important;
  }
  .moreAppDialogue .mainDiaogBox {
    min-width: unset !important;
    max-width: unset !important;
  }
  .login-page {
    .login-right {
      display: none;
    }
    .login-body{
      box-shadow: rgba(17, 17, 26, 0.1) -2px -2px 12px, rgba(17, 17, 26, 0.1) -3px 5px 24px, rgba(17, 17, 26, 0.1) 7px 20px 56px !important;
    }
    .login-content {
      border-radius: 31px !important;
      .login-mobile-img {
        display: block !important;
        .login-title {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          img {
            border: 2px solid #eee;
            border-radius: 14px;
          }
        }
      }
      h4 {
        font-size: 29px !important;
      }
      h6 {
        margin: 18px 0px 16px 0px !important;
      }
      .custom-btn button {
        height: 40px !important;
        font-size: 16px !important;
        max-width: 142px !important;
      }
    }
  }
  .ad-revenue-box{
    margin: 0px !important;
  }
  .switchData-btn{
    width: 40px !important;
    height: 40px !important;
    top: calc(47% - 2px) !important;
    left: 41% !important;
  }
  .add-details {

    .privacy-policy {
      margin-top: 10px !important;
      padding-left: 9px !important;
      padding-right: 0px !important;
      .inputData {
        padding-right: 12px !important;
      }
    }
  }
}

@media only screen and (max-device-width: 576px) {
  .allApp-table {
    .search-btnBox {
      display: flex;
      .custom-searching {
        display: flex;
        justify-content: center;
      }
      .new-button {
        justify-content: center !important;
      }
    }
  }
  .new-appCreate {
    .mainDiaogBox {
      min-width: unset !important;
      max-width: unset !important;
    }
  }
  .edit-renderComponent {
    padding: 20px 19px 16px 18px;
    .appUser {
      .search-input-box {
        flex-direction: column-reverse;
        .custom-searching {
          margin-top: 10px;
          display: flex;
          justify-content: center;
          input {
            width: calc(100% - 49px);
          }
        }
      }
    }
  }
  .custom-searching {
    input {
      width: calc(100% - 49px);
      max-width: 185px !important;
    }
  }
}
